import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

//import './App.scss';
import SSRProvider from 'react-bootstrap/SSRProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap/dist/react-bootstrap.js'

import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //     <Provider store={store}>
  //       <SSRProvider>
  //         <BrowserRouter>
  //           <App />
  //         </BrowserRouter>
  //       </SSRProvider>
  //     </Provider>
  // </React.StrictMode>

//https://reactjs.org/docs/strict-mode.html  double initial component for check life cycle but not impack production build
  <React.StrictMode>  
    <Provider>
      <SSRProvider>
        <BrowserRouter>
            <App />
            {/* <Route path="*" element={ <App />} /> */}
        </BrowserRouter>
      </SSRProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
